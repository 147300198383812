import logo from './logo.svg';
import './App.css';
import Routing from './component/common/Routing';


function App() {
  return (
    <>
    <Routing/>
    </>
  );
}

export default App;
